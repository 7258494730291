/*jslint browser: true*/
/*global error,success*/
(function($)
{
	$.notify = {
		ajaxMessageWrap: null,
		init: function()
		{	
            this.ajaxMessageWrap = $('#ajaxMessage');
            if (!this.ajaxMessageWrap.length) {
                $('body').prepend('<div id="ajaxMessage"></div>');
/*
                this.ajaxMessageWrap = $('#ajaxMessage').click(function () {
                    $.notify.hideAjaxMessages();
                });
*/
            }
            $('#ajaxMessage').off().on('click', '.close_btn', function () {
	            $.notify.hideAjaxMessages();
	            return false;
            });
            this.ajaxMessageStartup();
  		},
        ajaxMessageStartup: function () {
            if ((typeof error) !== 'undefined' && error !== null) {
	            $.notify.ajaxMessage('error',error);
            }
            if ((typeof success) !== 'undefined' && success !== null) {
	            $.notify.ajaxMessage('success',success);
            }
        },
        ajaxMessage: function (type, message) {
            if (type === 'processing') {
                this.hideAjaxMessages();
            } else if (type !== 'processing') {
                $('.ajaxMessage').fadeOut(function () {
                    $(this).remove();
                });
            }
            clearTimeout(this.ajaxMessageWrapTimeout);
            
            var $icons = {
	            'info' : '<i class="fa fa-info-circle"></i>',
	            'success' : '<i class="fa fa-check"></i>',
	            'error' : '<i class="fa fa-exclamation-triangle"></i>',
	            'processing' : '<i class="fa fa-circle-o-notch fa-spin"></i>',
            },
			$output = $('<div class="ajaxMessage ' + type + '"><span class="message">' + $icons[type] + ' ' + message + '</span><a href="#" class="close_btn"></a></div>');
            $output.prependTo(this.ajaxMessageWrap).fadeIn();
            this.ajaxMessageWrapTimeout = setTimeout(function () {
                $('.ajaxMessage.success').fadeOut(function () {
                    $(this).remove();
                });
            }, 3000);
        },
        hideAjaxMessages: function() {
            var animation = 'fadeOut';
            if (animation === 'fadeOut') {
                $('.ajaxMessage').fadeOut(function () {
                    $(this).remove();
                });
            } else if (animation === 'hide') {
                $(this).remove();
            }
		}
	};
	
	$(function()
	{
		$.notify.init();
	});
})(jQuery);
