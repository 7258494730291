/*jslint browser: true*/
/*global error,success*/
(function($)
{
	$.listview = {
		parent: null,
		init: function()
		{	
			if($('body').hasClass('is_mobile')) {
				return;
			}
			
			if($.listview.parent) {
				$.listview.parent.off("scroll");
			}
			
			var $this = $('.fix-scroll');
			if(!$this.length) {
				return;
			}
			
			$.listview.parent = ($this.data('parent') === 'window') ? $(window) :  $('#'+$this.data('parent'));
			var	$offset = $this.data('offset');
												
			$.listview.parent.on("scroll", function () {
																		
			    if ($.listview.parent.scrollTop() >= $offset && !$this.hasClass('is-fixed')) {
			        $this.addClass('is-fixed');
			        $(window).on('resize',$.listview.onresize);
			        $.listview.onresize($this);
			    }
			    if ($.listview.parent.scrollTop() < $offset && $this.hasClass('is-fixed')) {
			        $this.removeClass('is-fixed');
			        $(window).off('resize',$.listview.onresize);
			        $this.find("thead th.fluid").removeAttr('width');
			    }
			    
			});
		},
		onresize: function($this) {
	        // calculate not width column widths = 
			$('.fix-scroll').each(function() {
				var $this = $(this),
			        cols = $this.find('tbody tr:first td.fluid');
			        
				cols.each(function() {
					
					var $class = $(this).attr('class'),
						$th = $this.find("thead th[class='"+$class+"']");
																	
					$th.attr('width',$(this).outerWidth());
					
				});
				
			});
		},
		destroy: function() {
			// destroy is handled inside the init function
		},
		refresh: function() {
			//this.destroy();
			$.listview.init();
		}
	};
	
	$(function()
	{
		$.listview.init();
	});
})(jQuery);
