(function($)
{    
	// Ok lets go...
    $.dialog = {
		show: function(opts) {
			
            delete this.callback;
            delete this.callbackParams;
            
            log(opts.callbackParams);
            
            if (opts && opts.type && !$('#dialog-overlay').length) {
                if (opts.callback) {
                    this.callback = opts.callback;
                }
                if (opts.callbackParams) {
                    this.callbackParams = opts.callbackParams;
                }

				var html = '';
				html += '<div id="dialog-wrap">';
				html += 	'<div id="dialog-overlay">';

				html += 		'<div id="dialog">';
				html += 			'<div class="dialog-inner">';
				
	            if (opts.type === 'prompt') {
	                html += 			'<form action="" onsubmit="$.dialog.hide();return false;">';
	            }
	            
				html += 					'<div class="dialog-content">';
				html += 						'<div class="dialog-title">' + opts.title + '</div>';
	            if (opts.description) {
	                html += 					'<p>' + this.nativeConvert(opts.description) + '</p>';
	            }
				if(opts.type === 'prompt'){
					html +=						'<input class="field full" id="prompt_value" value="" />';
				}
				html += 					'</div>';
				
				$submitting = '';
				if(opts.actionTextSubmitting) {
					$submitting = 'data-submitting="'+opts.actionTextSubmitting+'"';
				}
				$submittingAlt = '';
				if(opts.actionAltTextSubmitting) {
					$submittingAlt = 'data-submitting="'+opts.actionAltTextSubmitting+'"';
				}

				if (typeof this.callback === 'function') {
					var altClass = (opts.actionText && opts.actionAltText) ? "multiple-actions x3":"multiple-actions ";
					html += 				'<div class="dialog-actions '+altClass+'">';
		            html += 					'<a href="javascript:;" onclick="$.dialog.hide(this,true); return false;">CANCEL</a>';
					if (opts.actionAltText) {
		            html += 					'<a href="javascript:;" '+$submittingAlt+' class="dialog-action-alt" onclick="$.dialog.hide(this); return false;">'+opts.actionAltText+'</a>';
					}
					if (opts.actionText) {
		            html += 					'<a href="javascript:;" '+$submitting+' class="dialog-action" onclick="$.dialog.hide(this); return false;">'+opts.actionText+'</a>';
					}
					html += 				'</div>';
				} else {
					var cancel = (opts.type === 'alert') ? "OK": "CANCEL" ;
					html += 				'<div class="dialog-actions">';
		            html += 					'<a href="javascript:;" onclick="$.dialog.hide(this,true); return false;">'+cancel+'</a>';
					html += 				'</div>';
				}
				
	            if (opts.type === 'prompt') {
	                html += 			'</form>';
	            }

				html += 			'</div>';
				html += 		'</div>';
				html += 	'</div>';
				html += '</div>';
				
				
				$('body').append(html);	
						
				$('#dialog-wrap').hide().fadeIn('fast',function(){
	                $('#dialog').addClass('visible');
				});

				$(document).on('keydown',this.keyboard);
            }
		},
		hide: function(event,cancelCallback) {
			
			$(document).off('keydown',this.keyboard);
			
			if (typeof this.callback === 'function' && !cancelCallback) {
                if (this.callbackParams) {
                   this.callback(this.callbackParams,event);
                } else {
                   this.callback(event);
                }
            }
            $('#dialog').removeClass('visible');
			$('#dialog-wrap').fadeOut('fast',function(){
				$(this).remove();
				$('#dialog').remove();
			});

		},
		keyboard: function(event) {
			if(event.keyCode == 13) {
				// Enter pressed...
				this.hide();
			}
			if(event.keyCode == 27) {
				// Escape pressed...
				this.hide(true);
			}
		},
		nativeConvert: function(str, is_xhtml) {
		    var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br />' : '<br>';    
		    return (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1'+ breakTag +'$2');
		},
		alert:function(opts) {
			//shortcut....
			opts.type = "alert";
			this.show(opts);
		},
		confirm:function(opts) {
			//shortcut....
			opts.type = "confirm";
			this.show(opts);
		},
		prompt:function(opts) {
			//shortcut....
			opts.type = "prompt";
			this.show(opts);
		}
	};
})(jQuery);