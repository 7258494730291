// Requires datedropper.js
(function($)
{    
	// Ok lets go...
    $.datepicker = {
		init: function() {
			$( ".datepicker" ).datetimepicker({
				timepicker:false,
				scrollMonth:false,
				scrollTime:false,
				scrollInput:false,
				format: "d/m/Y"
			});
			$( ".datetimepicker" ).datetimepicker({
				scrollMonth:false,
				scrollTime:false,
				scrollInput:false,
				step: 5,
				defaultTime: moment().hours()+':00',
				roundTime:'ceil',
				format: "d/m/Y @ H:i"
			});
			$( ".timepicker" ).datetimepicker({
				scrollMonth:false,
				scrollTime:false,
				scrollInput:false,
				step: 5,
				defaultTime: moment().hours()+':00',
				roundTime:'ceil',
				format: "H:i"
			});
		},
		refresh: function() {
			$( ".datepicker" ).datetimepicker('destroy');
			$( ".datetimepicker" ).datetimepicker('destroy');
			$( ".timepicker" ).datetimepicker('destroy');
			$('.xdsoft_datetimepicker').remove();
			$.datepicker.init();
		}
	};
	$(function () {
		$.datepicker.init();
	});
})(jQuery);