/*jslint browser: true*/
/*global plupload*/
(function($)
{
	$.uploads = {
		init: function()
		{
			// Init File Uplaods
			var $uploads = $('.upload');
			if($uploads.length <= 0) { return; }
			
			$uploads.each(function()
			{	            		
				$.uploads.setup($(this));
			});		
			
			// add events to show full image here...
			
		},
		setup: function($this) {
			var element = $this.attr('id'),
            	opts = $.parseJSON($this.attr('params')),
				uploader = new plupload.Uploader({
					runtimes : 'html5,html4',
					browse_button : element+'-browse',
					drop_element : element+'-drop',
					max_file_size : opts.maxFilesize,
					url : params.baseURL+'/'+params.appSlug+'/media/upload',
					multi_selection: false,
				    multipart_params : {
				        "_token" : params._token,
				        "object_type" : opts.object_type,
				        "object_id" : opts.object_id
				    },
		        	filters : [
						{title : "Files", extensions : opts.filetypes}
					],
					resize: {
					  width: 1000,
					  height: 1000,
					  crop: false,
					  quality: 80
					}
				});
		
			uploader.bind('Init', function(up) {
				//Get images previously uploaded
				$('#'+element+' .uploaded .file').each(function() {
					var file = {};
					file.id = $(this).attr('id');
					file.name = $(this).find('.filename').text();
					up.files.push(file);
				});
				//$('#'+element).after("<div>Current runtime: " + uploader.runtime + "</div>");
			});
			uploader.init();
				
			uploader.bind('Error', function(up, err) {
				$.dialog.alert({
					title: "Upload Error!",
					description: err.message
				});
				// Remove the file...
				up.removeFile(err.file);
			});
			
			var uploading = 0;
			uploader.bind('FilesAdded', function(up, files) {
				if (up.files.length > params.limit && params.limit !== '-1') {
					$.each(files, function(i, file) {
						up.removeFile(file);
					});
         			$.dialog.alert({
	         			title:'Uploader Error.', 
	         			description: 'You\'re only allowed to add a maximum of ' + params.limit + ' file(s).'
	         		});
         		} else {
	         		$('#item-row-no-results-document').hide();
					$.each(files, function(i, file) {
						$html = $.itemRows.render($('#document-progress-template').html(),file);
						if($('#'+element).find('tbody tr:visible ').length){
							$('#'+element).find('tbody tr:visible:last').after($html);
						} else {
							$('#'+element).find('tbody').prepend($html);
						}
					});
	         		
					uploader.start();
         		}    			
				up.refresh(); 
			});
						
			uploader.bind('UploadProgress', function(up, file) {
				$('#'+file.id).find('.progress').width(file.percent+'%');
			});
			
			uploader.bind('FileUploaded', function(up, file, response) {
				$fileDom = $('#'+file.id);
				response = $.parseJSON(response.response);	
				
				$data = {
					'id':$.stringRandom.generate(),
					'filename':response.success.document.filename,
					'mime':response.success.document.mime 
				}			
				$html = $.itemRows.render($('#document-file-template').html(),$data);
				$fileDom.remove();
				
				if($('#'+element).find('tbody tr:visible ').length){
					$('#'+element).find('tbody tr:visible:last').after($html);
				} else {
					$('#'+element).find('tbody').prepend($html);
				}
				
// 				$('#'+element).find('.upload-progress').width(0);
// 				$('#'+element+' .uploaded:first').append('<a href="#" class="file file-new" data-file="'+file.name+'"><img src="'+params.baseURL+'/media/image/'+file.name+'?width=60&height=60" /></a>');
            	            		
           		$.itemRows.reset_data();
			});
			uploader.bind('UploadComplete', function() {
				uploading = 0;
			});
		}
	};
	
	$(function()
	{
		// Initialize the forms methods
		$.uploads.init();
	});
})(jQuery);