(function($)
{    
	// Ok lets go...
    $.itemRows = {
		init: function() {
			if(!$('#invoice-rows').length) {
				return;
			}
			
			$("#invoice-rows table tbody").sortable({
				handle: '.sortable-handle',
				draggable: 'tr',
				filter: '.disabled',
				animation: 150,
				forceFallback: true,
				fallbackOnBody: true,
				onStart: function (/**Event*/evt) {
					setTimeout(function() {
			        	$('.sortable-fallback').html('<div class="sortable-helper"><span><i class="fa fa-file-text"></i></span></div>');
					}, 1);
			    },

			}); 
		},
		update_view: function(id) {
			
			var $type = $('#item-row-invoice-'+id).find('select[name="invoice['+id+'][type]"]').val();
		
			//reset
			$('#item-row-invoice-'+id).find('select[name="invoice['+id+'][type]"]').closest('td').removeAttr('colspan');
			$('#item-row-invoice-'+id).find('textarea[name="invoice['+id+'][description]"]').parent('td').removeAttr('colspan');
			$('#item-row-invoice-'+id).find('textarea[name="invoice['+id+'][description]"]').parent('td').show();
			$('#item-row-invoice-'+id).find('input[name="invoice['+id+'][quantity]"]').show().parent('td').show();
			$('#item-row-invoice-'+id).find('input[name="invoice['+id+'][unit_price]"]').parent('td').show();
			$('#item-row-invoice-'+id).find('.item-vat').closest('td').show();
			$('#item-row-invoice-'+id).find('.amount_string').parent('td').show();

			if ($type === 'No Qty/Time') {
				$('#item-row-invoice-'+id).find('input[name="invoice['+id+'][quantity]"]').hide().val('1.00');
				this.update_amount(id);
			} else if ($type === 'Comment') {
				$('#item-row-invoice-'+id).find('textarea[name="invoice['+id+'][description]"]').parent('td').attr('colspan',5);
				$('#item-row-invoice-'+id).find('input[name="invoice['+id+'][quantity]"]').parent('td').hide();
				$('#item-row-invoice-'+id).find('input[name="invoice['+id+'][unit_price]"]').parent('td').hide();
				$('#item-row-invoice-'+id).find('.item-vat').closest('td').hide();
				$('#item-row-invoice-'+id).find('.amount_string').parent('td').hide();
				this.clear_amount(id);
			} else if ($type === 'Divider') {
				$('#item-row-invoice-'+id).find('select[name="invoice['+id+'][type]"]').closest('td').attr('colspan',6);
				$('#item-row-invoice-'+id).find('textarea[name="invoice['+id+'][description]"]').parent('td').hide();
				$('#item-row-invoice-'+id).find('input[name="invoice['+id+'][quantity]"]').parent('td').hide();
				$('#item-row-invoice-'+id).find('input[name="invoice['+id+'][unit_price]"]').parent('td').hide();
				$('#item-row-invoice-'+id).find('.item-vat').closest('td').hide();
				$('#item-row-invoice-'+id).find('.amount_string').parent('td').hide();
				this.clear_amount(id);
			} else {
				this.update_amount(id);
			}
		},
		update_amount: function(id) {
			var $item = $('#item-row-invoice-'+id),
				$quantity 			= $item.find('input[name="invoice['+id+'][quantity]"]'),
				$unit_price 		= $item.find('input[name="invoice['+id+'][unit_price]"]'),
				$vat 				= $item.find('.item-vat'),
				$amount_num 		= $item.find('input[name="invoice['+id+'][amount_num]"]'),
				$amount 			= $item.find('.amount_string'),
				$quantity_clean 	= $quantity.val().trim().replace(/[^\d.:-]/g, ''),
				$unit_price_clean 	= $unit_price.val().trim().replace(/[^\d.-]/g, '');
								
			// do we need to  convert quanity string to decimal
			if ($quantity_clean.match(/[:]/g)) {
				var $quantity_integer = this.timeStringToFloat($quantity_clean);
				if(isNaN($quantity_integer)) {
					$quantity_integer = "1.00";
				}
				$quantity.val( ($quantity_clean !== '') ? $quantity_clean : '1.00' );
			} else {
				var $quantity_integer = parseFloat($quantity_clean).toFixed(2);
				if(isNaN($quantity_integer)) {
					$quantity_integer = "1.00";
				}
				$quantity.val( ($quantity_integer) ? $quantity_integer : '1.00' );
			}
			
			var $price_integer = parseFloat($unit_price_clean).toFixed(2)
			
			if(isNaN($price_integer)) {
				$price_integer = "0.00";
			}

			$unit_price.val( ($price_integer) ? $price_integer : '1.00' );
			
			$amount_integer = parseFloat( $quantity_integer * $price_integer ).toFixed(2);
			
			// add Vat...
			$amount_vat = parseFloat($amount_integer * parseFloat($vat.val())).toFixed(2);
						
			$amount_num.val($amount_integer);
			$amount.text('£'+$amount_integer+' GBP');
			
			// Set row to update on save...
			if($item.find('input[name="invoice['+id+'][action]"]').val() === 'no-update') {
				$item.find('input[name="invoice['+id+'][action]"]').attr('value', 'update');
			}
			
			this.update_totals();
			
		},
		clear_amount: function(id) {
			var $item = $('#item-row-invoice-'+id);
			$item.find('input[name="invoice['+id+'][quantity]"]').val('1.00');
			$item.find('input[name="invoice['+id+'][unit_price]"]').val('0.00');
			$item.find('.item-vat').val('0.2');
			$item.find('input[name="invoice['+id+'][amount_num]"]').val('0.00');
			$item.find('.amount_string').text('£0.00 GBP');
			
			// Set row to update on save...
			if($item.find('input[name="invoice['+id+'][action]"]').val() === 'no-update') {
				$item.find('input[name="invoice['+id+'][action]"]').attr('value', 'update');
			}

			this.update_totals();
		},
		update_totals: function() {
			var $subtotal_val 		= $('#subtotal'),
				$discount_val 		= $('#total_discount'),
				$discount_input 	= $('#discount').val(),
				$vat_val 			= $('#total_vat'),
				$amount_due_val 	= $('#total_due');
				
			if($('#invoice-rows table tbody tr:visible() ').length){
				
				var $subtotal = $vat_total = 0;
				
				$('#invoice-rows table tbody tr:visible() ').not('#item-row-no-results-invoice').each(function() {
					var $amount = $(this).find('.item-amount-num').val(),
						$vat 	= $(this).find('.item-vat').val();
																								
					$subtotal += parseFloat($amount);
					$vat_total += parseFloat($amount) * parseFloat($vat);
					
				});
				
				$subtotal_val.text('£'+$subtotal.toFixed(2)+' GBP');
				$vat_val.text('£'+$vat_total.toFixed(2)+' GBP');
				
				$amount_due = $subtotal + $vat_total;
				$amount_due_val.text('£'+$amount_due.toFixed(2)+' GBP');

/*
				if($discount_input !== "" && $discount_input !== "0") {
					
					$discount_total = $subtotal * (parseInt($discount_input) / 100);
					$vat_discount = $vat_total * (parseInt($discount_input) / 100);

					// update totals
					$vat_total -= $vat_discount;
					$subtotal -= $discount_total;
					$amount_due = $subtotal + $vat_total;
					$vat_val.text('£'+$vat_total.toFixed(2)+' GBP');
					$amount_due_val.text('£'+$amount_due.toFixed(2)+' GBP');

					$('#total_discount_row').show();
					$('#total_discount_row .discount_percentage').text('('+$discount_input+'%)');
					$('#total_discount_removed').text('-£'+$discount_total.toFixed(2)+' GBP');
					
				} else {
					$('#total_discount_row').hide();
					$('#total_discount_row .discount_percentage').text('(0%)');
					$('#total_discount_removed').text('-£0.00 GBP');
				}
*/
				
				
			} else {
				$subtotal_val.text('£0.00 GBP');
				$discount_val.text('£0.00 GBP');
				$vat_val.text('£0.00 GBP');
				$amount_due_val.text('£0.00 GBP');
/*
				$('#total_discount_row .discount_percentage').text('(0%)');
				$('#total_discount_removed').text('-£0.00 GBP');
*/
			}
			
		},
		timeStringToFloat: function(time) {
			var hoursMinutes = time.replace(/[-]/g, '').split(/[.:]/);
			var hours = parseInt(hoursMinutes[0], 10);
			var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
			return (hours + minutes / 60).toFixed(2);
			
			// add rounding to nearest minimal time charge...
			// so 1:22min -> 1:30hrs or 1.50 decimal
			// http://stackoverflow.com/questions/4968250/how-to-round-time-to-the-nearest-quarter-hour-in-javascript
			
		},
		clearFetchOperative: function() {
		    $('#add-operative-link').addClass('disabled');
		    $('#fetch_operative').empty().attr('disabled',true).trigger('change').parent().addClass('disabled');
		},
		fetchOperative: function() {
			
			$.itemRows.clearFetchOperative();
			
			// get available operatives...
			var start = $('#fetch_start_date').removeClass('validation_failed').val(),
				end = $('#fetch_end_date').removeClass('validation_failed').val();
				
			if (!start || start === '' || !end || end === '') {
				return;
			}
			
			start = moment($('#fetch_start_date').val(),"DD/MM/YYYY @ HH:mm");
			if (!start.isValid()) {
				$('#fetch_start_date').addClass('validation_failed');
				$.dialog.alert({
					title: "Validation Error",
					description: "Please specify a valid start date.",
				});
				return;
			}
			
			end = moment($('#fetch_end_date').val(),"DD/MM/YYYY @ HH:mm");
			if (!end.isValid()) {
				$('#fetch_end_date').addClass('validation_failed');
				$.dialog.alert({
					title: "Validation Error",
					description: "Please specify a valid end date.",
				});
				return;
			}
			
			if (end.isBefore(start)) {
/*
				$('#fetch_start_date').addClass('validation_failed');
				$('#fetch_end_date').addClass('validation_failed');
				$.dialog.alert({
					title: "Validation Error",
					description: "The end date must be greater then the start date.",
				});
*/
				return;
			}
			operative_id = $('#fetch_operative').val();
									
			$.notify.ajaxMessage('processing','loading...');
			
		    // before we fecth lets loop through newly created booking to make sure we've not duplicating...
			var added = $('#booking-rows table tbody tr:visible .action[value="create"]'),
				added_arr = [];
				
			$.each(added, function() {
				// convert dates to moment.js
				var this_start = moment($(this).siblings('.starts_at:first').val(),"DD/MM/YYYY @ HH:mm"),
					this_end = moment($(this).siblings('.ends_at:first').val(),"DD/MM/YYYY @ HH:mm");
									
				if(this_start.isValid() && this_start.isBefore(end) && this_end.isValid() && this_end.isAfter(start)) {
					added_arr.push($(this).siblings('.operative_id:first').val());
				}
			});

		    // fetch any temp deletions...
			var deleted = $('#booking-rows table tbody tr:hidden .action[value="delete"]'),
				deleted_arr = [];
				
			$.each(deleted, function() {
				if($(this).siblings('.booking_id:first').length) {
					deleted_arr.push($(this).siblings('.booking_id:first').val());
				}
			});
			
			var data = {
				_token : params._token,
				start : start.format(),
				end : end.format(),
				added : added_arr,
				deleted : deleted_arr
			}
			
			$.notify.ajaxMessage('processing','Loading...');
			
			if($.itemRows.loader) {
				$.itemRows.loader.abort();
			}

		    $('#add-operative-link').addClass('disabled');
		    $('#fetch_operative').empty().attr('disabled',true).trigger('change').parent().addClass('disabled');
		    
		  	  

			$.itemRows.loader = $.ajax({
	            url: params.baseURL+'/'+params.appSlug+'/jobs/fetch-operatives',
	            method: 'POST',
	            data : $.param(data),
	            dataType: 'json',
	            success: function(data) {
		            
		            // update operatives selectbox...
		            $('#add-operative-link').removeClass('disabled');
		            $('#fetch_operative').attr('disabled',false).parent().removeClass('disabled');
			        $('#fetch_operative').append('<option value="">Select an operative...</option>');
			       
		            $.each(data.success.operatives, function(i,item) {
			            $('#fetch_operative').append('<option data-name="'+item.name+'" value="'+item.user_id+'">'+item.name+' ('+item.email+')</option>');
		            });
		            
		            $('#fetch_operative').trigger('change');
		            
					$.notify.ajaxMessage('success',data.success.message);
					
	            },
				error: function(xhr) {
					if(xhr.status === 401) {
						$.ajaxload.renewSession();
					} else {
						$.notify.ajaxMessage('error',xhr.responseJSON.error.message);
/*
						var msg = "Sorry but there was an error:\n";
			            $.dialog.alert({
				            title:"Error!",
				            description:  msg + xhr.status + " " + xhr.statusText,
				            actionText:"OK"
				        });
						$.notify.hideAjaxMessages();
*/
					}
			        return;
				}

	          });
			
			
		},
		addOperative: function() {
			// validate fields...
			if (!$('#fetch_operative').val() || $('#fetch_operative').val() === '') {
				$('#fetch_operative').addClass('validation_failed');
				$.dialog.alert({
					title: "Validation Error",
					description: "Please select an operative.",
				});
				return;
			}

			var data = {
					id: $.stringRandom.generate(),
					operative_id: $('#fetch_operative').val(),
					operative_name: $('#fetch_operative option:selected').data('name')
				},
				target = 'booking';

            $('#item-row-no-results-'+target).hide();
			if($('#'+target+'-rows table tbody tr:visible ').length){
				$('#'+target+'-rows table tbody tr:visible:last ').after(this.render($('#'+target+'-rows-template').html(),data));
			} else {
				$('#'+target+'-rows table tbody').prepend(this.render($('#'+target+'-rows-template').html(),data));
			}
			// replace start at, end at & name with current details...
			$('#'+target+'-rows table tbody tr:visible:last .starts_at').val($('#fetch_start_date').val());
			$('#'+target+'-rows table tbody tr:visible:last .ends_at').val($('#fetch_end_date').val());
			$('#'+target+'-rows table tbody tr:visible:last .booked_out .list-label').html(data.operative_name+'<br/><span>'+$('#fetch_start_date').val()+' - '+$('#fetch_end_date').val()+'</span>');
						
			$.itemRows.clearFetchOperative();
			$('#fetch_start_date').val("");
			$('#fetch_end_date').val("");
			
            this.reset_data();
		},
		add: function(target) {
			
			var data = {
				id: $.stringRandom.generate() 
			}
			
            $('#item-row-no-results-'+target).hide();
			if($('#'+target+'-rows table tbody tr:visible ').length){
				$('#'+target+'-rows table tbody tr:visible:last ').after(this.render($('#'+target+'-rows-template').html(),data));
			} else {
				$('#'+target+'-rows table tbody').prepend(this.render($('#'+target+'-rows-template').html(),data));
			}
			// replace created at with current now time...
			$('#'+target+'-rows table tbody tr:visible:last .created_at input').val(moment().format("DD/MM/YYYY @ HH:mm"));
			$('#'+target+'-rows table tbody tr:visible:last .orderID').val($('#invoice-rows table tbody tr').length + 1);
            this.reset_data();
		},
		remove: function(type,id) {
			$.dialog.confirm({
				title: "Delete Item",
				description: "Are you sure you want to delete this item?",
				actionText: "Delete",
				callback: function() {
										
					var $row = $('#item-row-'+type+'-'+id),
						$parent = $row.parent();
																	
					$row.fadeOut(function() {
						if ($(this).find('input[name="'+type+'['+id+'][object_id]"]').val() !== '') {
							$(this).remove().insertAfter($parent.find('tr:last')).find('input[name="'+type+'['+id+'][action]"]').val('delete');
						} else {
							$(this).remove();
						}
						
						if(!$parent.find('tr:visible').length) {
							$('#item-row-no-results-'+type).show();

						}
						$.itemRows.update_totals();
					});
					
					return;
				}
			});
		},
		update: function(type,id) {
			if($('input[name="'+type+'['+id+'][action]"]').val() === "no-update") {
				$('input[name="'+type+'['+id+'][action]"]').val("update");
			}
		},
		render: function(template,data) {
		    return template.replace(/%(\w*)%/g,function(m,key){ return (data.hasOwnProperty(key) && data[key]) ? data[key] : ""; });
		},
		reset_data: function() {
            $.formInputs.init();
			$.formSelect2.init();
			$.formAutosize.init()
		},
	};
	$(function()
	{
		$.itemRows.init();
	});
})(jQuery);