/*jslint browser: true*/
(function($)
{
	$.formDirty = {
		init: function()
		{	
			// track ajaxloaded links, all standard links will be captured by the beforeunload function.
 			$(window).unbind('beforeunload').bind('beforeunload', $.formDirty.beforeunload);
 			$('form.track-changes').each(function() {
	 			$(this).attr('params',$(this).serialize());
 			});
 		},
 		beforeunload: function()
 		{
			if($.formDirty.isDirty()) {
				return "You have unsaved changes!";
			}
 		},
 		isDirty: function() {
	 		if($('form.track-changes').length) {
		 		var dirty = false;
	 			$('form.track-changes').each(function() {
		 			if($(this).attr('params') != $(this).serialize()) {
			 			dirty = $(this);
			 			return;
		 			}
	 			});
			 	return dirty;
	 		} else {
		 		return false;
		 	}
 		},
		postponeLoad: function(dirtyForm,url,target,pushState,method,urlData) {
		    $.dialog.confirm({
			    title : "Review Changes",
			    description: "You have made changes.\nDo you want to save or discard them?",
				actionText: "Save",
				actionTextSubmitting: "Saving <i class='fa fa-spinner fa-spin'></i>",
				actionAltText: 'Discard',
				callback: function($clickObj,params) {
					if($($clickObj).hasClass('dialog-action-alt')) {
						//alert('discard');
						$.ajaxload.load(url,target,pushState,method,urlData);
					} else {
						$.notify.ajaxMessage('processing','Processing...');
						$.ajax({
				            url: dirtyForm.attr('action'),
				            method: dirtyForm.attr('method'),
				            data: dirtyForm.find(":input").filter(function () {
									return $.trim(this.value).length > 0
								}).serialize(),
				            dataType: 'html',
				            success: function(data, textStatus, xhr) {					
								var data = $("<div>"+data+"</div>");
						        		            	
					            if(data.find('#success_message').length) {
									$.notify.ajaxMessage('success',data.find('#success_message').text().match("\"(.*)\"")[1]);
					            }
					            if(data.find('#error_message').length) {
									$.notify.ajaxMessage('error',data.find('#error_message').text().match("\"(.*)\"")[1]);
					            }
					            if(!data.find('#error_message').length && !data.find('#success_message').length) {
								    $.notify.hideAjaxMessages();
					            }
					            
					            // check if there are any validation errors, if so cancel the load...
								if(data.find('.validation_failed').length) {
									$("#"+target).html(data.find("#"+target));
									var offset = parseInt($('#main-content').css('padding-top').replace("px", "")) + 40;
									$("html, body").animate({ scrollTop: $('.validation_failed:first').closest('.field-wrapper').offset().top - offset }, 250);
									$.ajaxload.initJS();
								} else {
									$.ajaxload.load(url,target,pushState,method,urlData,true);
								}
					            
					            
				            },
				            error: function(xhr) {
								if(xhr.status === 401) {
									$.ajaxload.renewSession();
								} else {
									var msg = "Sorry but there was an error:\n";
						            $.dialog.alert({
							            title:"Error!",
							            description:  msg + xhr.status + " " + xhr.statusText,
							            actionText:"OK"
							        });
								}
								$.notify.hideAjaxMessages();
						        return;
				            }
				        });
					}
					return;
				}
		    });
			
		},
 		refresh: function() {
 			$(window).unbind('beforeunload', $.formDirty.beforeunload);
 			$('form.track-changes').removeAttr('params');
 			this.init();
 		}
	};
	
	$(function()
	{
		$.formDirty.init();
	});
})(jQuery);
