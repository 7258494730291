(function($)
{    
	// Ok lets go...
    $.modal = {
		show: function(opts,focus) {
			focus = (focus) ? focus : false;
			
            delete this.callback;
            delete this.cancelCallback;
            delete this.callbackParams;
            delete this.remainOpen;
            
            if (opts && !$('#modal-wrap').length) {
                if (opts.callback) {
                    this.callback = opts.callback;
                }
                if (opts.callbackParams) {
                    this.callbackParams = opts.callbackParams;
                }
                if (opts.cancelCallback && typeof opts.cancelCallback === 'function' ) {
                    this.cancelCallback = opts.cancelCallback;
                }
                if (opts.remainOpen) {
                    this.remainOpen = true;
                }
                
				var html = '';
				
				html += '<div id="modal-wrap">';
				html += 	'<div id="modal-overlay">';
                if (opts.callback) {
					html += 	'<form class="modal" id="modal" action="" method="POST" onsubmit="$.modal.hide($(this).find(\'button[type=submit]:focus\'));return false;">';
                } else {
	                if (!opts.formAction) {
	                    opts.formAction = "";
	                }
                    this.remainOpen = true;
					html += 	'<form class="modal ajaxform" id="modal" action="'+opts.formAction+'" method="POST" onsubmit="$.modal.hide($(this).find(\'button[type=submit]:focus\'));">';
                }
				html += 			'<div class="modal-inner">';
				html += 				'<div class="modal-header">';
				html += 					'<div class="modal-title">' + opts.title + '</div>';
				html += 				'</div>';
	            if (opts.search == true) {
					html += 				'<div class="modal-search">';
					html += 					'<div class="loading"><i class="fa fa-spinner fa-spin"></i></div>';
					html += 					'<input type="search" placeholder="Search">';
					html += 				'</div>';
	            }
				html += 				'<div class="modal-content">';
	            if (!opts.ajax && opts.content) {
		            html += 		opts.content;
	            }
				html += 				'</div>';
				
				$submitting = '';
				if(opts.actionTextSubmitting) {
					$submitting = 'data-submitting="'+opts.actionTextSubmitting+'"';
				}
				$submittingAlt = '';
				if(opts.actionAltTextSubmitting) {
					$submittingAlt = 'data-submitting="'+opts.actionAltTextSubmitting+'"';
				}
				
				var cancelText = (opts.cancelText) ? opts.cancelText : "CANCEL";

				if (opts.actionText) {
					var altClass = (opts.actionAltText) ? "multiple-actions x3":"multiple-actions ";
					html += 				'<div class="modal-actions '+altClass+'">';
		            html += 					'<button onclick="$.modal.hide($(this),true,true); return false;" class="modal-cancel">'+cancelText+'</button>';
					if (opts.actionAltText) {
		            html += 					'<button type="submit" '+$submittingAlt+' class="modal-action-alt">'+opts.actionAltText+'</button>';
					}
		            html += 					'<button type="submit" '+$submitting+' class="modal-action">'+opts.actionText+'</button>';
					html += 				'</div>';
				} else {
					html += 				'<div class="modal-actions">';
		            html += 					'<button onclick="$.modal.hide($(this),true,true); return false;" class="modal-cancel">'+cancelText+'</button>';
					html += 				'</div>';
				}
				html += 			'</div>';
				html += 		'</form>';
				html += 	'</div>';
				html += '</div>';
				
				$('body').append(html);			
	            if (opts.openCallback && typeof opts.openCallback === 'function') {
	            	// use event to track which btn was pressed and add to params
	                if (opts.openCallbackParams) {
	                   opts.openCallback(opts.openCallbackParams);
	                } else {
	                   opts.openCallback();
	                }
	            }

/*
				$('#modal-overlay').unbind().click(function() {
					$.modal.hide(this,true);
				}).hide().fadeIn('fast',function(){
	                $('#modal').addClass('visible');
				});
*/
				$('#modal-wrap').hide().fadeIn('fast',function(){
					if(opts.ajax) {
						$('#modal .modal-content').load(opts.ajax,function( response, status, xhr ) {
							if ( status == "error" ) {
								$.dialog.alert({title:"Unable to load view"});
								$.modal.hide(true);
							} else {
								$('#modal').addClass('visible');
							}
						});
					} else {
	                	$('#modal').addClass('visible');
					}
				});
            }
		},
		hide: function($clickObj,cancelCallback,forceClose,remainOpen) {
						
			if($('#modal').hasClass('submitting')) { return; }
			
            if (typeof this.cancelCallback === 'function' && $clickObj.hasClass('modal-cancel')) {
            	this.cancelCallback($clickObj);
            }
			
            if (typeof this.callback === 'function' && !cancelCallback) {
            	// use event to track which btn was pressed and add to params
                if (this.callbackParams) {
                   this.callback(this.callbackParams,$clickObj);
               } else {
                   this.callback($clickObj);
                }
            }
            
			if((this.remainOpen || remainOpen) && !forceClose) {
				return;	
			}
			
            $('#modal').removeClass('visible');
			$('#modal-wrap').fadeOut('fast',function(){
				$(this).remove();
				$('#modal').remove();
			});
		}
	};	
})(jQuery);