/*jslint browser: true*/
/*global params*/
(function($)
{    
	// Ok lets go...
    $.animateTo = {
		go: function(link)
		{	
			var target = $(link).attr('href'),
				offset = parseInt($('#main-content').css('padding-top').replace("px", ""));
				
			$("html, body").animate({ scrollTop: $(target).offset().top - offset }, 250);
			return false;				
		}		
	};
		
})(jQuery);