/*jslint browser: true*/
(function($)
{
	$.formSelect2 = {
		init: function()
		{	
			$(".select2-wrapper select").select2({
				theme: "ss",
				width : "100%",
				allowClear: true
			}).on('select2:open', function () {
				setTimeout(function() {
					$('.select2-search__field').blur();
				}, 1);
			});
		},
		reset:function() {
			if ($(".select2-wrapper").length > 0) {
				$(".select2-wrapper select").select2('destroy');
				this.init();
			}
		}
	};
	
	$(function()
	{
		$.formSelect2.init();
	});
})(jQuery);
