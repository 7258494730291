/*jslint browser: true*/
/*global params*/
(function($)
{    
	// Ok lets go...
    $.ajaxload = {
	    firstLoad: false,
		init: function()
		{	
			//detect the 'popstate' event - e.g. user clicking the back button
		  	$(window).on('popstate', function() {
			  	if( $.ajaxload.firstLoad ) {
					$.ajaxload.load(location.href);
					return false;
			    }
			    $.ajaxload.firstLoad = true;
			});
		
		    $(document).on('click','.ajaxload', function () {
			    
				var url = $(this).attr('href')
					target = ($(this).data('target')) ? $(this).data('target') : 'app',
					pushState = ($(this).data('pushstate')) ? $(this).data('pushstate') : true,
					dirtyForm = $.formDirty.isDirty()
					
			    if(!dirtyForm) {
					//url = url.replace(params.baseURL,"");
					if(url !== location.href){
						$.ajaxload.load(url,target,pushState);
					}
			    } else {
					$.formDirty.postponeLoad(dirtyForm,url,target,pushState);
			    }
			    
				return false;				
		    });
		    
		    $(document).on('submit','.ajaxform', function () {
			    
				var url = $(this).attr('action'),
					target = ($(this).data('target')) ? $(this).data('target') : 'app',
					pushState = ($(this).data('pushstate')) ? $(this).data('pushstate') : true;
										
				//url = url.replace(params.baseURL,"");
				data = $(this).find(":input").filter(function () {
					return $.trim(this.value).length > 0
				}).serialize();
				
				$.ajaxload.load(url,target,pushState,$(this).attr('method'),data);
				return false;				
		    });
		},
		initJS: function()
		{	
			$.datepicker.refresh();
			$.listview.refresh();
			$.formInputs.init();
			$.formDirty.refresh();
			$.formSelect2.init();
			$.formAutosize.init();
			$.uploads.init();
			$.itemRows.init();
			$.dropdown.init();
		},
		load: function(url,target,pushState,method,data,supressMessages) {
						
			method = (method) ? method : 'GET';
			data = (data) ? data : null;
			target = (target) ? target : 'app';
			supressMessages = (supressMessages) ? true : false;
			
			$.ajaxload.firstLoad = true;
			if(!supressMessages) {
				if(method !== 'GET') {
					$.notify.ajaxMessage('processing','Processing...');
				} else {
					$.notify.ajaxMessage('processing','Loading...');
				}
			}
			$.ajaxload.cancel();
			$.ajaxload.loader = $.ajax({
	            url: url,
	            method: method,
	            data: data,
	            dataType: 'html',
	            success: function(data, textStatus, xhr) {		
		            
				    if(url.replace(params.baseURL,"") === '/sign-in' || url.replace(params.baseURL,"").indexOf("/admin/users/force-login/") >= 0) {
						location.reload();
						return;
				    }
				    
					var data = $("<div>"+data+"</div>");
					
					if(!supressMessages) {
			            if(data.find('#success_message').length) {
							$.notify.ajaxMessage('success',data.find('#success_message').text().match("\"(.*)\"")[1]);
			            }
			            if(data.find('#error_message').length) {
							$.notify.ajaxMessage('error',data.find('#error_message').text().match("\"(.*)\"")[1]);
			            }
			            if(!data.find('#error_message').length && !data.find('#success_message').length) {
						    $.notify.hideAjaxMessages();
			            }
			        }
				    
				    $('#modal-wrap').remove();
					$("#"+target).replaceWith(data.find("#"+target));

					if($('.validation_failed').length) {
						log(data.find('.validation_failed'));
						var offset = parseInt($('#main-content').css('padding-top').replace("px", "")) + 40;
						$("html, body").animate({ scrollTop: $('.validation_failed:first').closest('.field-wrapper').offset().top - offset }, 250);
					} else {
						$('html, body').animate({scrollTop : 0},250);
					}
					
					// override if server sends an Original Location header
					if(xhr.getResponseHeader('Original Location')){
						url = xhr.getResponseHeader('Original Location');
					}
																	
					if(url != location.href){
				        //add the new page to the window.history
				        url = url.replace(params.baseURL,"");
						if(pushState) {
							window.history.pushState({path: url},'',url);
						}
				    }
				    
				    $.ajaxload.initJS();
	            },
				error: function(xhr) {
					if(xhr.status === 0) {
						log(xhr.status + " " + xhr.statusText);
						return;
					} else if(xhr.status === 401) {
						$.ajaxload.renewSession();
					} else {
						var msg = "Sorry but there was an error:\n";
			            $.dialog.alert({
				            title:"Error!",
				            description:  msg + xhr.status + " " + xhr.statusText,
				            actionText:"OK"
				        });
					}
					$.notify.hideAjaxMessages();
			        return;
				}
	          });
	    },
		renewSession: function() {
						
		    $.dialog.prompt({
			    title : "Session expired",
			    description: "Please enter your password again.\nYou will not move away from this page?",
				actionText: "Login",
				actionTextSubmitting: "Login <i class='fa fa-spinner fa-spin'></i>",
				callback: function($clickObj,opts) {
					$.notify.ajaxMessage('processing','Processing...');
					var data = {
						_token : params._token,
						email : params.userEmail,
						password : $('#prompt_value').val()
					}
					$.ajax({
			            url: params.baseURL+"/renew-session/",
			            method: 'POST',
			            data: data,
			            dataType: 'json',
			            success: function(data, textStatus, xhr) {					
							$.notify.ajaxMessage('success',data.success.message);
							
							// store new token on page...
							params._token = data.success.token;
							$('input[name="_token"]').first().val(data.success.token);
							
			            },
			            error: function(data) {
							$.notify.ajaxMessage('error',data.responseJSON.error.message);
							$.ajaxload.renewSession();
			            },
			        });
					return;
				}
			});
			
		},
		cancel: function() {
			if($.ajaxload.loader) {
				$.ajaxload.loader.abort();
			}
		},
		
	};
	
	$(function()
	{
		$.ajaxload.init();
	});
	
})(jQuery);