/*jslint browser: true*/
/*global console,params,alert,wp*/
(function ($) {
  "use strict";    
  $.navigationOffcanvas = {
    init: function () {
	    
	    //open-close lateral menu clicking on the menu icon
		$(document).on('click','#trigger-nav', function(event){
			event.preventDefault();
			if($('#body').hasClass('menu-is-open')) {
				$.navigationOffcanvas.close();
			} else {
				$.navigationOffcanvas.open();
			}
		});
	
		//close  menu clicking outside the menu itself
		$('body').on('click', function(event){
			if( !$(event.target).is('#trigger-nav, #trigger-nav > *') ) {
				$.navigationOffcanvas.close();
			}
		});
			
	},
	close: function() {
		$('body').removeClass('overflow-hidden');
		$('#trigger-nav').parent().removeClass('active');
		$('#body').removeClass('menu-is-open');
	},
	open: function() {
		$('body').addClass('overflow-hidden');
		$('#trigger-nav').parent().addClass('active');
		$('#body').addClass('menu-is-open');
	}
  };
  $(function () {
    $.navigationOffcanvas.init();
  });
}(jQuery));