/*jslint browser: true*/
(function($)
{
	$.formInputs = {
		submit: function()
		{	
			// prevent submitting forms on enter key...
			$(document).on("keypress", "input", function(event) { 
				if(event.keyCode == 13 && $(this).parents('form').attr('id') !== 'loginForm') {
					event.preventDefault();
					return false;
				}
			});
/*
		    $(document).on('submit','form', function () {
			    var $form = $(this);
			    if($form.hasClass('submitting')) { return false; }
			    // update action text...
			    $(this).find('button[type="submit"]').each(function() {
				    var $submit = $(this);
				    if($submit.attr('data-submitting')) {
					    $submit.html($submit.attr('data-submitting'));
				    }
			    });
			    $form.addClass('submitting');
		    });
*/

		},
		init: function()
		{	
		    $('.select-wrapper').each(function () {
			    if(!$(this).children('span').length) {
		    		$(this).prepend('<span>' + $(this).find('select option:selected').text() + '</span>');
			    }
		    });
		    
		    $('.select').unbind().not('[readonly]').change(function () {
		        $(this).prev().replaceWith('<span>' + $(this).find('option:selected').text() + '</span>');
		    }).focus(function() {
		        $(this).parent().addClass('focus');
		    }).blur(function() {
		        $(this).parent().removeClass('focus');
		    });
		    
		    $('.checkbox').each(function () {
		        if ($(this).is(':checked')) {
		            $(this).parents('.checkbox-wrapper').addClass('checked');
		        }
		    });
		
		    $('.checkbox').unbind().not('[readonly]').change(function () {
		        var checkbox = $(this);
		        var wrapper = $(this).parents('.checkbox-wrapper');
		        if (checkbox.is(':checked')) {
		            wrapper.addClass('checked').removeClass('validation_failed');
		        } else if (checkbox.not(':checked')) {
		            wrapper.removeClass('checked');
		        }
		    });
		    
		    $('.radio').each(function () {
		        if ($(this).is(':checked')) {
		            $(this).parents('.radio-wrapper').addClass('checked');
		        }
		    });
		    
 		    $('.radio').unbind().not('[readonly]').change(function () {
		        var radio = $(this);
		        var wrapper = $(this).parents('.radio-wrapper');
		        var container = wrapper.parents('.field-wrapper');
		        
		        container.find('.radio-wrapper').removeClass('checked');
		        if (radio.is(':checked')) {
		            wrapper.addClass('checked').removeClass('validation_failed');
		        }
		    });
		    
 		},
  		restrictParams: function($form) {
		    $form.find("input, textarea, select", this).each(function() {
		        if ($(this).val() === '' || $(this).val() === null) {
		            $(this).removeAttr('name');
		        }
		    });
  			//return false;
  		}
	};
	
	$(function()
	{
		$.formInputs.init();
		$.formInputs.submit();
	});
})(jQuery);
