/*jslint browser: true*/
(function($)
{
	$.stringRandom = {
		generate: function(length)
		{	
			length = (length) ? length : 5;
		    var text = "";
		    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
		    for( var i=0; i <= length; i++ ) {
		        text += possible.charAt(Math.floor(Math.random() * possible.length));
		    }
		    return text;
		},
	};
})(jQuery);
