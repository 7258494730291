/*jslint browser: true*/
(function ($) {
  "use strict";    
  $.dropdown = {
    init: function () {
		var $dropdown_trigger = $('.trigger-dropdown > a');
		
		$.each($dropdown_trigger,function() {
			var $this = $(this),
				$dropdown = $($this.attr('data-dropdown'));
				
			//open dropdown clicking on the dropdown icon
			$this.off().on('click',function(event){
				event.preventDefault();
				$.dropdown.open($dropdown,$this);
			})
		});
		
		$('.dropdown-close').on('click',function(event){
			event.preventDefault();
			$.dropdown.close();
		});				
	},
	close: function() {
		$('.dropdown,.trigger-dropdown > a').removeClass('dropdown-is-active');
	},
	open: function($dropdown,$dropdown_trigger) {
		
		var navIsVisible = ( $dropdown.hasClass('dropdown-is-active') ) ? true : false,
			$wrapper = $('body');
		
		$.dropdown.close();
		
		if(!navIsVisible) {
			$dropdown.addClass('dropdown-is-active');
			$dropdown_trigger.addClass('dropdown-is-active');
			$dropdown.find('input:text,textarea').first().focus();
			$wrapper.on( "click", function(event) {
				if(!$(event.target).parents('.trigger-dropdown').length && !$(event.target).parents('.dropdown').length) {
					$.dropdown.close();
					$wrapper.off(event);
				}
			});
		}
		
	}
  };
  $(function () {
    $.dropdown.init();
  });
}(jQuery));