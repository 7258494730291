/*jslint browser: true*/
(function($)
{
	$.formAutosize = {
		init: function()
		{	
			autosize($('.autosize'));
  		}
	};
	
	$(function()
	{
		$.formAutosize.init();
	});
})(jQuery);
