/*jslint browser: true*/
/*global console,debug*/
var debug = true,
log = function () {
	if (debug) {
		var args = Array.prototype.slice.call(arguments),
			log;
		try {
			log = Function.prototype.bind.call(console.log, console);
			log.apply(this, args);
		} catch (e) {}
	}
};